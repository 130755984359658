import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  useEffect } from "react";
import styled from 'styled-components';
import useLocalStorage from "../hooks/useLocalStorage";
import { CHAIN_ID, injected } from "../web3s/constants";
import { tokenAddress } from "../web3s/contracts";
import useActiveWeb3React from "../web3s/hooks/useWeb3";
import { SocialLinks } from "./social";

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin: 0 auto;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 575px) {
   
    button {
      max-width: 140px;
      line-height: 1.2;
    }
    padding: 0px;
  }

`

const StyledDropdown = styled.div`
  position: relative;
  font-weight: 600;

  .button {
    background: #41381F;
    border-radius: 4px;
    text-align: center;
    position: relative;
    min-width: 164px;
    padding: 10px 24px;
    cursor: pointer;
    font-size: 18px;
    background: transparent;
    border: 2px solid #f3d5b8;
    color: #f3d5b8;
    height: 50px;
    max-width: 200px !important;
    width: 100% !important;

    @media (max-width: 567px) {
      max-width: 100% !important;
      width: 100%;
      
    }

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border-width: 0 2px 2px 0;
      border-color: #f3d5b8;
      border-style: solid;
      position: absolute;
      top: 16px;
      right: 15px;
      transform: rotate(45deg);
    }
  }

  .dropdown {
    display: none;
    position: absolute;
    opacity: 0;
    left: 0;
    top: 47px;
    background: #1C2E50;
    border-radius: 10px;
    padding: 15px 0;
    border: 1px solid #888;
    z-index: 1000;

    ul {
      margin-bottom: 0;
      padding-left: 0;
    }

    li {
      list-style: none;
    }

    a {
      display: block;
      min-width: 200px;
      padding: 10px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: #eff6f44b;;
      }
    }
  }

  &:hover {
    .dropdown {
      opacity: 1;
      display: block;
    }
  }
`;

const TokenMenu = (props) => {
  const { className } = props;

  const addToken = async () => {
    try {
      // @ts-ignore
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: 'GANA', // A ticker symbol or shorthand, up to 5 chars.
            decimals: 5, // The number of decimals in the token
            image: '', // A string url of the token logo
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  return <StyledDropdown className={className} >
    <div className="button" >GANA</div>
    <div className="dropdown" >
      <ul>
        <li>
          <a rel="noreferrer" href="https://app.bogged.finance/bsc/swap?tokenIn=BNB&tokenOut=0x3848DF333F329aB00acDC3CDC4574b4eE4399dE7" target="_blank" >Buy $GANA</a>
        </li>
        <li>
          <a rel="noreferrer" href="#null" onClick={addToken} >Add Token to Metamask</a>
        </li>
      </ul>
    </div>
  </StyledDropdown>
}

export const Header = (props) => {
  const { openNav } = props;
  const { account, chainId, activate, deactivate } = useActiveWeb3React();
  const [isDark, setIsDark] = useLocalStorage('theme', false);

  const connect = async () => {
    await activate(injected);
  }

  const disconnect = async () => {
    await deactivate();
  }

  const switchNetwork = () => {
    try {
      // @ts-ignore
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }]
      });
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    document.body.classList.toggle('dark-theme', isDark);
  }, [isDark])

  return <StyledHeader>
    {/* <Link to='/'>
      <img src='/images/logo.png' width='200' alt="logo" />
    </Link> */}
    <div onClick={() => setIsDark(!isDark)} className="btn btn-primary toggle-btn">
      <span className="text">{!isDark ? 'Dark' : 'Light'} Mode</span>
      <span className="icon ml-2">
        <i className={`${!isDark ? 'far fa-moon' : 'far fa-sun'}`}></i>
      </span>
    </div>
    <FontAwesomeIcon onClick={() => openNav()} size='lg' className="d-inline-block d-lg-none menu-icon" icon={faBars} />
    <span />
    <div className="d-flex align-items-center header-icons" >
      <SocialLinks className="me-4 d-none d-lg-block" />
      <TokenMenu className="me-0 me-sm-3" />
      {
        account ?
          (chainId === CHAIN_ID ? <button className="" onClick={disconnect} >Disconnect</button> : <button onClick={switchNetwork} className="golden-bg" >Switch Network</button>)
          : <button className="golden-bg" onClick={connect} >Connect Wallet</button>
      }
    </div>
  </StyledHeader>
}