import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { socialLinks } from "../constants/config.js";
import styled from 'styled-components';

const SocialWraper = styled.div`
  a {
    display: inline-block;
    width: 36px;
    height: 36px;
    border: 1px solid var(--box-color);
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    margin: 0px 5px;
    opacity: 0.7;
    position: relative;

    svg {
      position: relative;
      path{
        fill: var(--box-color);
      }
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--box-color);;
      opacity: 0;
      transition: opacity 0.2s ease;
      border-radius: 50%;
    }

    &:hover {
      opacity: 1;
      border-color: var(--box-color);;

      &:before {
        opacity: 1;
      }
      svg {
        position: relative;
        path{
          fill: var(--box-bg);
        }
      }
    }
  }
`;

export const SocialLinks = (props) => {
  const {className} = props;

  return <SocialWraper className={`social-links ${className}`} >
    {
      socialLinks.map((item, id) => <a rel="noreferrer" target='_blank' href={item.link} key={item.name} >
        <FontAwesomeIcon size="sm" icon={item.icon} />
      </a>)
    }
  </SocialWraper>
}
