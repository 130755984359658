import React, { useState } from "react";
import { Page } from "../page";
import styled from 'styled-components';
import { Box as Box0, Container, Counter, Value } from "../home";
import { useAccountStats, useCommonStats } from "../../web3s/hooks/useStats";
import { formatPrice ,numberFormat } from "../../web3s/utils/calc";
import useActiveWeb3React from "../../web3s/hooks/useWeb3";
import { APY, REBASE_RATE, REBASE_RATE_1d, REBASE_RATE_5d } from "../../constants";

const Box = styled(Box0)`
  padding-bottom: 15px;

  label:last-child {
    background: var(--div-bg);
    /* padding: 10px 15px; */
    color: var(--box-color);
    font-size: 15px;
    border-radius: 6px;
  }

  h2 {
    font-weight: 900;
    font-size: 2em;
    color: var(--blue-color);
  }

  &.stats {
    display:  block;
    & > div {
      padding: 15px 20px;
      font-weight: 600;
    }
    & > div:nth-child(2n + 1) {
      background: var(--div-bg);
      color: var(--box-color);
    }
    & > div:nth-child(2n + 2) {
      background: var(--body-bg);
      color: var(--box-color);
    }
  }
`;

export const Account = () => {

  const [updater, setUpdater] = useState(1);

  const {account, chainId} = useActiveWeb3React();

  const stats = useCommonStats(updater);

  const myStats = useAccountStats(account, chainId, updater);

  return <Page>
    <Container >
      <div className="row">
        <div className="col-12 col-xl-4">
          <Box>
            <img src="/images/11.png" alt="" />
            <label>Your Balance</label>
            <Value>${numberFormat(myStats.balance * stats.tokenPrice)}</Value>
            <label>{numberFormat(myStats.balance)} GANA</label>
          </Box>
        </div>
        <div className="col-12 col-xl-4">
          <Box>
            <img src="/images/22.png" alt="" />
            <label>APY</label>
            <Value>{formatPrice(APY, 1)}%</Value>
            <label>Daily ROI 2.28%</label>
          </Box>
        </div>
        <div className="col-12 col-xl-4">
          <Box>
            <img src="/images/55.png" alt="" />
            <label>Next Rebase:</label>
            <Value>
            <Counter until={stats.lastRebaseTime + 450} onEnd={() => setUpdater(updater + 1)} />
            </Value>
            <label>You will earn money soon</label>
          </Box>
        </div>
      </div>

      <Box className="stats py-4 px-2 px-md-5" >
        <h2 className="mb-3 mt-2" >Statistics</h2>
        <div className="d-flex justify-content-between">
          <span>Current GANA Price</span>
          <span>${numberFormat(stats.tokenPrice)}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Next Reward Amount</span>
          <span>{formatPrice((myStats.balance * REBASE_RATE / 100), 4)}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Next Reward Amount USD</span>
          <span>${formatPrice(myStats.balance  * stats.tokenPrice * REBASE_RATE / 100, 4)}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Next Reward Yield</span>
          <span>{REBASE_RATE}%</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>ROI(1-Day Rate) USD</span>
          <span>${formatPrice(myStats.balance * stats.tokenPrice  * REBASE_RATE_1d / 100) }</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>ROI(5-Day Rate)</span>
          <span>{REBASE_RATE_5d}%</span>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <span>ROI(5-Day Rate) USD</span>
          <span>${formatPrice(myStats.balance * stats.tokenPrice * REBASE_RATE_5d / 100)}</span>
        </div>
      </Box>
    </Container>
  </Page>
}