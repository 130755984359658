import {  faTelegram ,faGithub } from "@fortawesome/free-brands-svg-icons";
import { faBook, faBorderAll, faCalculator, faCircleUser, faExchange } from "@fortawesome/free-solid-svg-icons";


export const menuItems = [
  {
    link: '/',
    title: 'Dashboard',
    icon: faBorderAll
  },
  {
    link: '/account',
    title: 'Account',
    icon: faCircleUser
  },
  {
    link: '/calculator',
    title: 'Calculator',
    icon: faCalculator
  },
  {
    link: 'https://app.bogged.finance/bsc/swap?tokenIn=BNB&tokenOut=0x3848DF333F329aB00acDC3CDC4574b4eE4399dE7',
    title: 'Swap',
    icon: faExchange
  },
  {
    link: '',
    title: 'Docs',
    icon: faBook
  }
]

export const socialLinks = [
  {
    link: 'https://github.com/Ganautostaking',
    icon: faGithub,
    name: 'github'
  },
  // {
  //   link: '',
  //   icon: faTwitter,
  //   name: 'twitter'
  // },
  // {
  //   link: '',
  //   icon: faReddit,
  //   name: 'reddit'
  // },
  {
    link: 'https://t.me/ganautostaking',
    icon: faTelegram,
    name: 'Telegram'
  },

];
