import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { menuItems } from "../constants/config.js";
import styled from 'styled-components';
import { Header } from "./header";
import { SocialLinks } from "./social";
import useActiveWeb3React from "../web3s/hooks/useWeb3";
import { trimAddress } from "../web3s/utils";
import { CHAIN_ID } from "../web3s/constants";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Sidebar = styled.div`
  width: 360px;
  background: var(--blue-color);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding-bottom: 50px;

  @media (max-width: 991px) {
    width: 100%;
    left: -100%;
    transition: left 0.3s ease;
    z-index: 1000;

    &.open {
      left: 0;
    }

    .close-icon {
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }

  .menu-items {
    li {
      list-style: none;
      position: relative;
      font-size: 24px;

      svg {
        font-size: 1.2em;
        vertical-align: middle;
      }

      span {
        margin-left: 10px;
        font-weight: 500;
      }

      &:hover{
        background: #eff6f44b;
      }
      &.active {
        background: #eff6f44b;

        &::before {
          content: '';
          position: absolute;
          width: 10px;
          left: 0;
          top: 0;
          height: 100%;
          background: #ff0191;
          display: block;
        }

        
      }

      a {
        display: block;
        padding: 18px 20px 18px 50px;

        &:hover {
          
        }
      }
    }

    .active {
      color: var(--text-highlight);
    }
  }

  .social-icons {
    a {
      transition: transform 0.2s ease;

      &:hover {
        color: var(--text-highlight);
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
`

const RightSide = styled.div`
  margin-left: 360px;
  padding: 40px 0 0;

  @media (max-width: 991px) {
    margin-left: 0;
  }
`;

const Footer = styled.footer`
  border-top: 1px solid var(--border);
  text-align: center;
  padding: 15px;
  background: var(--blue-color);;
  margin-top: 40px;
  font-weight: 400;
  font-size: 16px;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // z-index: 10;
  color: #fff;

  span {
    font-weight: 900;
    color: #fff;
    font-weight: bold;
    margin: 0 10px;
    text-decoration: underline;
  }
`;

export const Menu = (props) => {
  const {children} = props;

  const location = useLocation();

  const {account, chainId} = useActiveWeb3React();

  const [navOpen, setNavOpen] = useState(false);

  const openNav = () => {
    setNavOpen(true);
  }

  return <div className="h-100" >
    <Sidebar className={`${navOpen?'open':''}`} >
      <FontAwesomeIcon size="lg" icon={faTimes} className='close-icon d-inline-block d-lg-none' style={{  fontSize: '30px', cursor: 'pointer'}} onClick={()=>setNavOpen(false)} />
      <div className="text-center" >
        <Link to='/' >
          <img src='/images/mini-logo.png' alt="token" height="60%" width="60%" />
        </Link>
      </div>
      <div className="text-center" style={{minHeight:30}} >
        {(account && chainId===CHAIN_ID)? trimAddress(account||'') : ' ' }
      </div>
      <ul className="menu-items px-0 pt-xl-4" >
        {
          menuItems.map((item, id) => <li key={`menu-${item.title}`} className={location.pathname===item.link?'active':''} >
            {
              item.link.startsWith('https://')?
              <a rel="noreferrer" target='_blank' href={item.link} >
                <FontAwesomeIcon icon={item.icon} /> <span>{item.title}</span>
              </a> : <Link to={item.link} >
                <FontAwesomeIcon icon={item.icon} /> <span>{item.title}</span>
              </Link>
            }
          </li>)
        }
      </ul>

      <SocialLinks className="d-block d-lg-none text-center" />
    </Sidebar>
    <RightSide>
      <div className="container">
        <Header openNav={openNav} />
        {children}
      </div>
      <Footer>
        Copyright © 2022 <span >GANA</span> All rights reserved.
      </Footer>
    </RightSide>
  </div>
}
