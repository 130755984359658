

export const calcBnbPriceBusd = (stats) => {
  // if (stats.bBal_BU.eq(BN_ZERO)) return 0;

  // return stats.uBal_BU.mul(10000).div(stats.bBal_BU).toNumber() / 10000;
  return 0;
}

export const calcTokenPriceBnb = (stats) => {
  // if (stats.oBal_OB.eq(BN_ZERO)) return 0;

  // return stats.bBal_OB.div(stats.oBal_OB).div(BigNumber.from(10).pow(18 - OCTRO_DECIMALS - 4)).toNumber() / 10000;
  return 0;
}

export const formatPrice = (num, decimals=2) => {
  // @ts-ignore
  num = parseFloat(num);
  return parseFloat(num.toFixed(decimals)).toLocaleString();
}


export const numberFormat = (num)=>{
    return new Intl.NumberFormat('ja-JP', { maximumSignificantDigits: 3 }).format(num)
}
