import React, { useEffect, useState } from "react";
import { Page } from "../page";
import styled from 'styled-components';
import { useCommonStats } from "../../web3s/hooks/useStats";
import { numberFormat } from "../../web3s/utils/calc";


export const Container = styled.div`
  padding-top: 60px;

  .col-12 {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const Box = styled.div`
background: var(--box-bg);
padding: 20px 10px;
border-radius: 20px;
margin-bottom: 30px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

  @media (max-width: 575px) {
    padding: 20px 15px;
  }

  img {
    width: 80px;
    height: 80px;
  }

  label {
    color: var(--box-color);
        margin-top: 10px;
        font-weight: 600;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 17px;
        display: block;
  }
`;
export const Value = styled.p`
color: var(--box-color);
font-size: 17px;
margin-top: 10px;
// font-weight: 700;
font-family: "gilmer-heavy";
`;

export const formatTime = sec => {
  let re = sec;
  const h = Math.floor(sec / 3600);
  const m = Math.floor((re % 3600) / 60);
  const s = re % 60;

  return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
}

export const Counter = (props) => {
  const { until, onEnd } = props;
  const [time, setTime] = useState('00:00:00');

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Math.floor(new Date().getTime() / 1000);
      const diff = (until >= now) ? until - now : 0;

      if (diff === 1 && onEnd) {
        onEnd();
      }

      setTime(formatTime(diff));
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [until]);

  return <span className="counter">
    {time}
  </span>
}

export const Home = () => {

  const [updater, setUpdater] = useState(1);

  const stats = useCommonStats(updater);

  return <Page>
    <Container>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/11.png" alt="" />
            
            <label>GANA Price</label>
            <Value>
              ${numberFormat(stats.tokenPrice)}
            </Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/22.png" alt="" />
            <label>Marktet Cap</label>
            <Value>${numberFormat(stats.marketcap)}</Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/33.png" alt="" />
            <label>Circulating Supply</label>
            <Value>{numberFormat(stats.circSupply)}</Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/44.png" alt="" />
            <label>Backed Liquidity</label>
            <Value>100%</Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/55.png" alt="" />
            <label>Next Rebase</label>
            <Value>
              <Counter until={stats.lastRebaseTime + 450} onEnd={() => setUpdater(updater + 1)} />
            </Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/66.png" alt="" />
            <label>Total Supply</label>
            <Value>{numberFormat(stats.totalSupply)}</Value>
          </Box>
        </div>



        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/11.png" alt="" />
            <label>GANA Price</label>
            <Value>${numberFormat(stats.tokenPrice)}</Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/77.png" alt="" />
            <label>Market Value of Treasury Asset</label>
            <Value>${numberFormat(stats.treasuryAsset)}</Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/88.png" alt="" />
            <label>Pool Value</label>
            <Value>${numberFormat(stats.poolvalue)}</Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/99.png" alt="" />
            <label>GANA Insurance Fund Value</label>
            <Value>${numberFormat(stats.insurancevalue)}</Value>
          </Box>
        </div>


      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/111.png" alt="" />
            <label># Value of FirePit</label>
            <Value>{numberFormat(stats.firpitintoken)} GANA</Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/222.png" alt="" />
            <label>$ Value of FirePit</label>
            <Value>${numberFormat(stats.firpitinusd)}</Value>
          </Box>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 col-xxl-2">
          <Box>
            <img src="/images/333.png" alt="" />
            <label>% FirePit: Supply</label>
            <Value>${stats.firpitper}%</Value>
          </Box>
        </div>
      </div>



    </Container>
  </Page>
}